
import { defineComponent } from "vue";
import GenreRecord from "@/model/genres/GenreRecord";
import { getHomeState } from "@/state/HomeState";

export default defineComponent({
  
  mounted(){
      if (this.big){
        this.$el.classList.add('big');
      }
  },
  props: {
    big:{
        type : Boolean,
        required : false,
        default : false,
    },
    genre: {
      type: GenreRecord,
      required: true,
    },
    image: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});
