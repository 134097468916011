export let imageCache = new Map();

export function drawText(
  ctx: CanvasRenderingContext2D,
  content: string,
  x: number,
  y: number,
  fontSize: string = "12px",
  color: string = "white",
  font: string = "Kanit, sans-serif"
) {
  ctx.font = fontSize + " " + font;
  ctx.fillStyle = color;
  ctx.fillText(content, x, y);
}

export function drawRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  color: string,
  border: boolean = false,
  borderColor: string = ""
) {
  ctx.fillStyle = color;
  ctx.fillRect(x, y, width, height);

  if (border) {
    ctx.strokeStyle = borderColor;
    ctx.strokeRect(x, y, width, height);
  }
}

export function drawLine(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  x2: number,
  y2: number,
  color: string
) {
  ctx.strokeStyle = color;
  ctx.beginPath(); // Start a new path
  ctx.moveTo(x, y); // Move the pen to (30, 50)
  ctx.lineTo(x2, y2); // Draw a line to (150, 100)
  ctx.stroke(); // Render the path
}
export function drawImage(
  ctx: CanvasRenderingContext2D,
  path: string,
  x: number,
  y: number,
  w: number,
  h: number
) {
  if (imageCache.has(path)) {
    ctx.drawImage(imageCache.get(path), x, y, w, h);
    return;
  }
  // Create a new Image object
  const img = new Image();

  // Set the source of the image
  img.src = path; // Replace with your image URL

  // Draw the image on the canvas when it's fully loaded
  img.onload = function () {
    imageCache.set(path, img);
    ctx.drawImage(img, x, y, w, h);
  };
}
export function drawRoundedRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
  stroke: boolean = false
) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.arcTo(x + width, y, x + width, y + height, radius);
  ctx.arcTo(x + width, y + height, x, y + height, radius);
  ctx.arcTo(x, y + height, x, y, radius);
  ctx.arcTo(x, y, x + width, y, radius);
  ctx.closePath();
  ctx.fill(); // or ctx.stroke() if you want an outline

  if (stroke) {
    ctx.stroke();
  }
}
