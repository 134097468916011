import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-394a4046"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex col g24" }
const _hoisted_2 = { class: "bold light" }
const _hoisted_3 = { class: "light f14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "card",
    style: _normalizeStyle(
      _ctx.image
        ? 'background-image: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.1)), url(' +
          _ctx.genre.illustrationUrl +
          ');background-size:cover;'
        : 'background:rgb(40, 40, 50)'
    )
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.genre.name), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.genre.description), 1)
    ])
  ], 4))
}