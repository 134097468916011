import IvoryMeasure from "@/model/sheets/IvoryMeasure";
import IvorySheet from "@/model/sheets/IvorySheet";
import IvoryStaveNote from "@/model/sheets/IvoryStaveNote";
import { StaveEnum } from "@/model/sheets/StaveEnum";
import VF, { Beam, Dot, Note, Stave, StaveNote, StaveTie, Voice } from "vexflow";

export let STAVE_WIDTH = 320;

export let SHEET_Y_OFFSET = 200;

export let PADDING_X = 50;

export let GAP_BETWEEN_GRAND_STAFF = 250;

export let GAP_BETWEEN_STAFF = 100;

export default class SheetDrawer {
  canvas: HTMLCanvasElement;
  sheet: IvorySheet;

  context: VF.RenderContext | null;

  allNotes : Map<IvoryStaveNote,Note> = new Map();

  x: number = 0;
  y: number = 0;

  constructor(canvas: HTMLCanvasElement, sheet: IvorySheet) {
    this.canvas = canvas;
    this.sheet = sheet;
    this.context = null;
  }

  renderBar(
    clef: string,
    bar: IvoryMeasure,
    barX: number,
    barY: number
  ): VF.Stave | null {
    var stave = new VF.Stave(barX, barY, STAVE_WIDTH);

    if (this.x == 0 && this.y == 0 && clef == "treble") {
      stave!.setTempo(
        { bpm: this.sheet.tempo, duration: "q", dots: 0, name: "Tempo" },
        -60
      );
    }

    if (this.x == 0) {
      stave
        .addClef(clef)
        .addTimeSignature(
          this.sheet?.numberOfBeatPerMeasure + "/" + this.sheet?.beatNoteValue
        );
    }
    if (bar.notes.length == 0) {
      return null;
    }

    var notes = bar.notes.map(
      (note) =>
        new VF.StaveNote({
          clef: clef,
          keys: [note.keys],
          duration: note.duration,
          stem_direction: -1,
        })
    );

   
     

    for (let i = 0; i < bar.notes.length; i++) {

      var n = bar.notes[i];

      this.allNotes.set(n,notes[i]);

      if (n.duration.includes("d")) {
        Dot.buildAndAttach([notes[i]]);
      }
    }

    var voice = new VF.Voice({
      num_beats: this.sheet?.numberOfBeatPerMeasure!,
      beat_value: this.sheet?.beatNoteValue!,
    })
      .addTickables(notes)
      .setMode(Voice.Mode.SOFT);

    new VF.Formatter().joinVoices([voice]).formatToStave([voice], stave);
    var beams = this.buildBeams(bar, notes);

    stave.setContext(this.context!).draw();

    voice.draw(this.context!, stave);

    if (beams.length > 0) {
      beams.forEach((x) => x.setContext(this.context!).draw());
    }

     
  

    return stave;
  }

  buildBeams(bar: IvoryMeasure, notes: StaveNote[]): VF.Beam[] {
    var results: VF.Beam[] = [];

    const groupedByBeams = bar.notes.reduce<Map<number, IvoryStaveNote[]>>(
      (res, note) => {
        const beamId = note.beamId;

        if (beamId == null) {
          return res;
        }

        // Check if the Map already has this beamId
        if (!res.has(beamId)) {
          res.set(beamId, []);
        }

        // Push the current note into the appropriate group
        res.get(beamId)!.push(note); // Use non-null assertion since we've checked existence

        return res;
      },

      new Map<number, IvoryStaveNote[]>()
    );

    if (groupedByBeams.size > 0) {
      for (let pair of groupedByBeams.values()) {
        let vexNotes: StaveNote[] = [];

        for (let note of pair.values()) {
          let index = bar.notes.indexOf(note);

          var vexNote = notes[index];
          vexNotes.push(vexNote);
        }

        var beam = new VF.Beam(vexNotes, false);
        results.push(beam);
      }
    }

    return results;
  }

  drawTitle() {
    this.context!.setFont("Arial", 20, "bold").setBackgroundFillStyle("black");

    const titleX =
      this.canvas.width / 2 -
      this.context!.measureText(this.sheet!.title).width / 2;

    this.context!.fillText(this.sheet!.title, titleX, 50);
  }
  getClef(staveEnum: StaveEnum) {
    switch (staveEnum) {
      case StaveEnum.Bass:
        return "bass";
      case StaveEnum.Treble:
        return "treble";
    }
  }
  draw() {
    var renderer = new VF.Renderer(this.canvas, VF.Renderer.Backends.CANVAS);

    //renderer.resize(this.canvas.width, this.canvas.height);

    this.context = renderer.getContext();

    this.drawTitle();

    let barCount = Math.min(
      this.sheet?.firstStave.bars.length!,
      this.sheet?.secondStave.bars.length!
    );

    for (let barId = 0; barId < barCount; barId++) {
      let trebleBar = this.sheet?.firstStave.bars[barId]!;

      let bassBar = this.sheet?.secondStave.bars[barId]!;

      var trebleX = this.x * STAVE_WIDTH + PADDING_X;
      var trebleY = this.y * GAP_BETWEEN_GRAND_STAFF + SHEET_Y_OFFSET;

      var trebleStave = this.renderBar(
        this.getClef(this.sheet?.firstStave.staveEnum),
        trebleBar,
        trebleX,
        trebleY
      );

      var bassX = this.x * STAVE_WIDTH + PADDING_X;
      var bassY =
        this.y * GAP_BETWEEN_GRAND_STAFF + SHEET_Y_OFFSET + GAP_BETWEEN_STAFF;

      var bassStave = this.renderBar(
        this.getClef(this.sheet?.secondStave.staveEnum),
        bassBar,
        bassX,
        bassY
      );

      if (this.x == 0) {
        var brace = new VF.Flow.StaveConnector(
          trebleStave!,
          bassStave!
        ).setType(3); // 3 = brace

        brace.setContext(this.context!).draw();
      }

      this.x++;

      if (this.x > 3) {
        this.x = 0;
        this.y++;
      }
    }


    const groupedByTies = Array.from(this.allNotes.keys()).reduce<Map<number, IvoryStaveNote[]>>(
      (res, note) => {
        const tieId = note.tieId;

        if (tieId == null) {
          return res;
        }

        // Check if the Map already has this beamId
        if (!res.has(tieId)) {
          res.set(tieId, []);
        }

        // Push the current note into the appropriate group
        res.get(tieId)!.push(note); // Use non-null assertion since we've checked existence

        return res;
      },

      new Map<number, IvoryStaveNote[]>()
    );

    console.log(groupedByTies);
    for (let group of groupedByTies)
    {
      var notes = group[1];

      for (let i = 0;i < notes.length-1;i++)
      {
          var n1 = this.allNotes.get(notes[i]);
          var n2 = this.allNotes.get(notes[i+1]);

          const tie = new StaveTie({first_note:n1,last_note:n2  });
          tie.setContext(this.context!).draw();
      }
    }
    
    
  }
}
