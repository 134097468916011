export default class SessionSettings {
  public displayChords: boolean = true;
  public displayNoteLines: boolean = false;
  public pauseOnScroll: boolean = false;
  public displayNoteOutline: boolean = false;
  public displayKeyNames: boolean = false;
  public notesColor: string = "#4287f5";
  public backgroundUrl: string | null = "";
  public displayPedalTailing: boolean = false;
  public notesColorLeftHand: string = "#5489de";
}
