
import { defineComponent, nextTick } from "vue";
import * as DOMUtils from "@/utils/DOMUtils";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Info",
      required: true,
    },
    noHeader: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  expose: ["open", "close", "context", "onClose"],
  data() {
    return {
      context: null,
    };
  },
  unmounted() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
  },
  methods: {
    onKeyDown(event: any) {
      if (event.key == "Escape" && this.isOpen()) {
        this.close();
      }
    },
    isOpen() {
      return this.$el.style.display != "none";
    },
    close() {
      this.$el.classList.remove("anim");
      this.$el.querySelector(".modal-content").classList.remove("anim-content");
      this.$el.style.display = "none";

      this.$emit("onClose");
    },

    open(context: any = null) {
      this.context = context;
      this.$el.style.display = "flex";
      this.$el.style.visibility = "visible";
      this.$el.classList.add("anim");
      this.$el.querySelector(".modal-content").classList.add("anim-content");
    },
  },
});
