
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import { HalfCircleSpinner } from "epic-spinners";
import SongRow from "@/components/home/SongRow.vue";
import * as IvoryApi from "@/IvoryApi";
import ArtistRow from "@/components/home/ArtistRow.vue";
import SearchResults from "@/model/SearchResults";
import IvorySong from "@/model/songs/IvorySong";
import * as ColorUtils from "@/utils/ColorUtils";
import { getHomeState } from "@/state/HomeState";
import router from "@/router";
import Genres from "@/components/home/Genres.vue";
import GenreRecord from "@/model/genres/GenreRecord";
import mixpanel from "mixpanel-browser";

export default defineComponent({
  data() {
    var state = getState();
    var homeState = getHomeState();

    return {
      results: new SearchResults([], []),
      searching: false,
      state: state,
      resultMessage: "",
      homeState: homeState,
    };
  },

  expose: ["searchByArtist"],

  computed: {
    itemList(): any {
      return {
        backgroundColor: ColorUtils.darkenColor(
          this.state.getNoteColorHex(),
          50
        ),
      };
    },
  },

  mounted() {
    if (this.homeState.search != "") {
      this.onSearchChange(this.homeState.search);
      this.performSearch(this.homeState.search);
    }
  },

  methods: {
    back() {
      this.results = new SearchResults();
      this.homeState.search = "";
    },
    async onGenreClick(genre: GenreRecord) {
      this.results = new SearchResults();
      this.resultMessage = "Searching...";
      this.searching = true;
      this.results = (await IvoryApi.searchByGenre(genre.id))!;
      this.searching = false;
      this.resultMessage = "";

      mixpanel.track("Searching genre", { genre: genre.name });
    },
    async playSong(record: IvorySong) {
      var state = getState();

      state.querySongId = record.id;
      router.push("player");

      /*    this.$el.classList.add("readonly-element");

      await state.playSong(record);

      this.$el.classList.remove("readonly-element"); */
    },

    async searchByArtist(artist: string) {
      this.results = new SearchResults();
      this.resultMessage = "Searching...";
      this.searching = true;
      this.results = (await IvoryApi.searchByArtist(artist))!;
      this.searching = false;
      this.resultMessage = "";
    },

    async performSearch(value: string) {
      mixpanel.track("Search", { value: value });

      if (value == "") {
        this.searching = false;
        this.resultMessage = "";
        return;
      }

      this.results = (await IvoryApi.searchMidiSongs(value))!;

      if (this.results == null) {
        this.results = new SearchResults();
        this.resultMessage =
          "Unable to reach Ivory API. The server might be offline.";
      } else if (this.results.getLength() == 0) {
        this.resultMessage =
          "Can't find what you're looking for? You can transcribe any song from youtube on the 'Transcription' page.";
      } else {
        this.resultMessage = "";
      }

      this.searching = false;
    },
    async onSearchChange(value: string) {
      this.searching = true;
      this.results = new SearchResults();

      this.resultMessage = "Searching...";
    },
  },
  components: { HalfCircleSpinner, SongRow, ArtistRow, Genres },
});
