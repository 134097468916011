import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import PlayerHeader from "@/components/PlayerHeader.vue";
import HomeBar from "./components/home/HomeBar.vue";
import { HalfCircleSpinner } from "epic-spinners";
import { FulfillingBouncingCircleSpinner } from "epic-spinners";
import IvoryButton from "@/components/global/IvoryButton.vue";
import IvoryLogo from "@/components/IvoryLogo.vue";
import IvoryIcon from "@/components/global/IvoryIcon.vue";
import IvoryColorPicker from "@/components/global/IvoryColorPicker.vue";
import Key from "@/components/player/keys/Key.vue";
import IvoryInputFile from "@/components/global/IvoryInputFile.vue";
import IvoryBar from "@/components/global/IvoryBar.vue";
import IvoryInput from "@/components/global/IvoryInput.vue";
import IvorySlider from "@/components/global/IvorySlider.vue";
import IvoryCheckbox from "@/components/global/IvoryCheckbox.vue";
import IvoryStars from "@/components/global/IvoryStars.vue";
import VLazyImage from "v-lazy-image";
import { HollowDotsSpinner } from "epic-spinners";
import vue3GoogleLogin from "vue3-google-login";
import "./registerServiceWorker";
import ReadonlyField from "./components/global/ReadonlyField.vue";
import IvoryBanner from "./components/global/IvoryBanner.vue";
import mixpanel from "mixpanel-browser";
import IvoryModal from "./components/global/IvoryModal.vue";

mixpanel.init("2e35c15033f52ba32c6a67e81ae2de42", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

const app = createApp(App);

app.use(createPinia());

app.component("HomeBar", HomeBar);
app.component("PlayerHeader", PlayerHeader);
app.component("LazyImage", VLazyImage);
app.component("HalfCircleSpinner", HalfCircleSpinner);
app.component("HollowDotsSpinner", HollowDotsSpinner);
app.component("IvoryModal", IvoryModal);
app.component("ReadonlyField", ReadonlyField);
app.component(
  "FulfillingBouncingCircleSpinner",
  FulfillingBouncingCircleSpinner
);
app.component("IvoryButton", IvoryButton);
app.component("IvoryInput", IvoryInput);
app.use(router).mount("#app");
app.component("IvoryLogo", IvoryLogo);
app.component("IvoryIcon", IvoryIcon);
app.component("IvoryColorPicker", IvoryColorPicker);
app.component("IvoryInputFile", IvoryInputFile);
app.component("Key", Key);
app.component("IvoryBar", IvoryBar);
app.component("IvorySlider", IvorySlider);
app.component("IvoryCheckbox", IvoryCheckbox);
app.component("IvoryStars", IvoryStars);
app.component("IvoryBanner", IvoryBanner);
