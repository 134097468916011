
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import { useRouter } from "vue-router";
import mixpanel from "mixpanel-browser";
import * as Modals from "@/utils/Modals";
import * as IvoryApi from "@/IvoryApi";
import IvorySong from "@/model/songs/IvorySong";

import Sheet from "@/model/sheets/IvorySheet";
import IvoryMeasure from "@/model/sheets/IvoryMeasure";
import SheetDrawer from "@/managers/SheetDrawer";

export default defineComponent({
  data() {
    return {
      state: getState(),
      loaded: false,
      sheet: null as Sheet | null,
      Sheet: Sheet,
      hidden: true,
    };
  },
  setup() {
    var router = useRouter();
    return {
      router: router,
    };
  },
  computed: {},

  methods: {
    goHome() {
      this.router.push({ name: "home" });
    },

    renderBar(trebleBar: IvoryMeasure, bassBar: IvoryMeasure) {},
  },

  async mounted() {
    mixpanel.track("Sheet view", {});

    if (this.hidden) {
      return;
    }
    this.sheet = await IvoryApi.getSheet(this.state.querySongId!);

    console.log(this.sheet);

    var refs = <any>this.$refs;

    const element = refs.header as HTMLElement;
    const rect = element.getBoundingClientRect();

    const canvas = refs.canvas;
    canvas.width = window.innerWidth;
    canvas.height =
      window.innerHeight - (rect.top + window.scrollY + rect.height) + 2000;

    var drawer = new SheetDrawer(canvas, this.sheet!);
    drawer.draw();

    this.loaded = true;
  },
});
