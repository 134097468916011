import IvoryStave from "./IvoryStave";

export default class IvorySheet {
  public tempo: number = 0;

  public numberOfBeatPerMeasure: number = 4;

  public beatNoteValue: number = 4;

  public firstStave: IvoryStave = new IvoryStave();

  public secondStave: IvoryStave = new IvoryStave();

  public measureDurationSeconds: number = 0;

  public totalDurationSeconds: number = 0;

  public title: string = "";
}
