import AccountRecord from "./model/accounts/AccountRecord";
import IvorySongData from "./model/songs/IvorySongData";
import SearchResult from "./model/SearchResults";
import LoginResponse from "./model/accounts/LoginResponse";
import LogoutResponse from "./model/accounts/LogoutResponse";
import ProcessingTask from "./model/ProcessingTask";
import TranscriptionResult from "./model/TranscriptionResult";
import SearchResults from "./model/SearchResults";
import IvorySong from "./model/songs/IvorySong";
import RegisterRequest from "./model/accounts/RegisterRequest";
import RegisterResponse from "./model/accounts/RegisterResponse";
import SessionSettings from "./model/accounts/SessionSettings";
import GenreRecord from "./model/genres/GenreRecord";
import Sheet from "./model/sheets/IvorySheet";

export let ApiUrl = "http://localhost:5091/";

// process.env.NODE_ENV !== "development"
if (process.env.NODE_ENV !== "development") {
  ApiUrl = "https://studio.ivory-app.com:5092/";
}

export let ApiToggleLeftHand = ApiUrl + "transcription/toggleLeftHand";

export let ApiSearchByName = ApiUrl + "midi/searchByName";

export let ApiGetSongData = ApiUrl + "midi/getSongData";

export let ApiGetSong = ApiUrl + "midi/getSong";

export let ApiSearchByArtist = ApiUrl + "midi/searchByArtist";

export let ApiUploadFile = ApiUrl + "transcription/upload";

export let ApiGetTasks = ApiUrl + "transcription/tasks";

export let ApiGetTask = ApiUrl + "transcription/task";

export let ApiProcessYoutube = ApiUrl + "transcription/processYoutube";

export let ApiLogin = ApiUrl + "account/login";

export let ApiLogout = ApiUrl + "account/logout";

export let ApiGetLoggedAccount = ApiUrl + "account/getLogged";

export let ApiGetFavorites = ApiUrl + "account/favorites";

export let ApiToggleFavorite = ApiUrl + "account/toggleFavorite";

export let ApiRemoveSong = ApiUrl + "account/delete";

export let ApiUpdateSettings = ApiUrl + "account/updateSettings";

export let ApiRegister = ApiUrl + "account/register";

export let ApiRateSong = ApiUrl + "midi/rate";

export let ApiGetGenres = ApiUrl + "genre";

export let ApiSearchByGenre = ApiUrl + "genre/search";

export let ApiGetMyUploads = ApiUrl + "account/myUploads";

export let ApiDownloadMidi = ApiUrl + "midi/download";

export let ApiGetSheet = ApiUrl + "transcription/sheet";

export async function rateSong(songId: number, n: number) {
  return await httpRequest("GET", ApiRateSong + "?id=" + songId + "&n=" + n);
}
export async function updateAccountSettings(settings: SessionSettings) {
  let json = JSON.stringify(settings);
  console.log(json);
  console.log(settings.notesColorLeftHand);
  await httpRequest("POST", ApiUpdateSettings, json, "application/json");
}

export async function register(
  username: string,
  email: string,
  password: string
) {
  var request: RegisterRequest = new RegisterRequest(username, email, password);
  return (await httpRequest(
    "POST",
    ApiRegister,
    JSON.stringify(request),
    "application/json"
  )) as RegisterResponse | null;
}
export async function toggleLeftHand(songId: number, noteIndex: number) {
  return await httpRequest(
    "GET",
    ApiToggleLeftHand + "?songId=" + songId + "&noteIndex=" + noteIndex
  );
}
export async function removeSong(songId: number) {
  return await httpRequest("GET", ApiRemoveSong + "?id=" + songId);
}
export function getMidiDownloadLink(songId: number) {
  return ApiDownloadMidi + "?id=" + songId;
}
export async function getFavorites() {
  let results = await httpRequest("GET", ApiGetFavorites);

  if (results == null) {
    return null;
  }

  let songs: IvorySong[] = [];

  for (let result of results) {
    var song = Object.assign(new IvorySong(), result);
    songs.push(song);
  }

  return songs;
}
export async function getLoggedAccount() {
  return (await httpRequest(
    "GET",
    ApiGetLoggedAccount
  )) as AccountRecord | null;
}
export async function login(
  login: string,
  password: string
): Promise<LoginResponse | null> {
  return (await httpRequest(
    "POST",
    ApiLogin,
    JSON.stringify({ login: login, password: password }),
    "application/json"
  )) as LoginResponse | null;
}
export async function logout(): Promise<LogoutResponse | null> {
  return (await httpRequest("GET", ApiLogout)) as LogoutResponse | null;
}

export async function requestProcessingYoutube(
  url: string,
  genreId: number
): Promise<TranscriptionResult | null> {
  const formData = new FormData();
  formData.append("url", url);
  formData.append("genreId", genreId.toString());

  return (await httpRequest(
    "POST",
    ApiProcessYoutube,
    formData
  )) as TranscriptionResult | null;
}

export async function getRunningTasks(): Promise<ProcessingTask[] | null> {
  let results = [] as ProcessingTask[];

  var req = await httpRequest("GET", ApiGetTasks);

  for (let value of req) {
    results.push(Object.assign(new ProcessingTask(), value));
  }

  return results;
}
export async function getRunningTask(
  id: number
): Promise<ProcessingTask | null> {
  return (await httpRequest(
    "GET",
    ApiGetTask + "?id=" + id
  )) as ProcessingTask | null;
}

export async function requestSongData(
  id: number
): Promise<IvorySongData | null> {
  return (await httpRequest(
    "GET",
    ApiGetSongData + "?id=" + id
  )) as IvorySongData | null;
}
export async function requestSong(
  id: number,
  withData: boolean
): Promise<IvorySong | null> {
  return (await httpRequest(
    "GET",
    ApiGetSong + "?id=" + id + "&withData=" + withData
  )) as IvorySong | null;
}

export async function getSheet(id: number) {
  return (await httpRequest("GET", ApiGetSheet + "?id=" + id)) as Sheet | null;
}

export async function searchByArtist(
  artist: string
): Promise<SearchResults | null> {
  return Object.assign(
    new SearchResult(),
    await httpRequest("GET", ApiSearchByArtist + "?artist=" + artist)
  );
}

export async function searchMidiSongs(
  search: string
): Promise<SearchResults | null> {
  return Object.assign(
    new SearchResult(),
    await httpRequest("GET", ApiSearchByName + "?search=" + search)
  );
}

export async function getGenres(): Promise<GenreRecord[] | null> {
  var result = await httpRequest("GET", ApiGetGenres);

  return result == null ? result : result.map((x) => GenreRecord.fromJson(x));
}
export async function searchByGenre(
  genreId: number
): Promise<SearchResults | null> {
  return Object.assign(
    new SearchResult(),
    await httpRequest("GET", ApiSearchByGenre + "?genreId=" + genreId)
  );
}

export async function uploadFile(
  name: string,
  author: string,
  genreId: number,
  file: any
) {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("author", author);
  formData.append("genreId", genreId.toString());
  formData.append("file", file);

  let res = await httpRequest("POST", ApiUploadFile, formData);
  return res as TranscriptionResult | null;
}

export async function toggleFavorite(id: number): Promise<boolean> {
  let result = await httpRequest("GET", ApiToggleFavorite + "?id=" + id);
  return result.exists;
}

export async function getMyUploads() {
  let queryRes = await httpRequest("GET", ApiGetMyUploads);

  let results: IvorySong[] = [];

  for (let result of queryRes) {
    var song = Object.assign(new IvorySong(), result);
    results.push(song);
  }
  return results;
}

export function httpRequest(
  method: string,
  url: string,
  body: any = null,
  contentType: string | null = null
): Promise<any | null> {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();

    xhr.withCredentials = true;
    xhr.open(method, url, true);

    if (contentType && body) {
      xhr.setRequestHeader("Content-Type", contentType);
    }

    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          if (xhr.responseText != "") {
            resolve(JSON.parse(xhr.responseText));
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
          //  reject(xhr.status); // Reject the promise with the status code
        }
      }
    };

    if (body == null) {
      xhr.send();
    } else {
      xhr.send(body);
    }
  });
}
